/* Benutzerspezifische Scrollbar für den Scrollbereich der Tabelle */
.ant-table-content {
  /* Anpassen der Standardbreite und Farben der Scrollbar */
  scrollbar-width: thin !important; /* Für Firefox */
  scrollbar-color: #0084f746 #e0e0e0 !important; /* Für Firefox */
}
.ant-table-content::-webkit-scrollbar {
  height: 10px !important;
  width: 10px !important; /* Für Chrome, Safari, Edge */
}
.ant-table-content::-webkit-scrollbar-thumb {
  background-color: #0084f746 !important;
  border-radius: 40px !important;
}
.ant-table-content::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
}
